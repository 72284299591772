import "./Button.css";

interface IButtonProps {
  text: string;
  onPress?: () => void;
  visible?: boolean;
  dimBackground?: boolean;
  color: "white" | "black";
}

export function Button(props: IButtonProps) {
  return (
    <div
      className={`more-details-button more-details-button-${props.color}`}
      onClick={props.onPress}
      style={{
        backgroundColor: `rgba(0, 0, 0, ${props.dimBackground ? 0.3 : 0})`,
        display: props.visible === false ? "none" : "inline",
      }}
    >
      {props.text}
    </div>
  );
}

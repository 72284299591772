import { useState } from "react";
import "./ProjectTile.css";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button";

interface IProjectTileProps {
  imageSrc: string;
  title: string;
  projectKey: string;
  subtitle?: string;
  clickable?: boolean;
  onClick?: (projectKey: string) => void;
  useRounded?: boolean;
}

export function ProjectTile(props: IProjectTileProps) {
  const [overlay, displayOverlay] = useState(false);
  const [isButtonVisible, shouldDisplayButton] = useState(true);
  const { t } = useTranslation();
  function enableOverlay() {
    if (props.clickable || props.subtitle) {
      displayOverlay(true);
      const displayButton = props.clickable !== undefined ? true : false;
      shouldDisplayButton(displayButton);
    }
  }
  function enableOverlayAndHideButton() {
    if (props.clickable || props.subtitle) {
      displayOverlay(true);
      shouldDisplayButton(false);
    }
  }
  function hideOverlay() {
    displayOverlay(false);
  }

  const displaySubtitle = props.subtitle ? "inline" : "none";
  return (
    <div
      onMouseEnter={enableOverlay}
      onMouseLeave={hideOverlay}
      onClick={() => {
        if (props.onClick) props.onClick(props.projectKey);
      }}
      onTouchStart={enableOverlayAndHideButton}
      onTouchEnd={hideOverlay}
      className="project-tile"
    >
      <div
        className="project-details-tile"
        style={{
          display: overlay ? "inline" : "none",
          borderRadius: props.useRounded ? "8px" : "0px",
        }}
      >
        <h3>{props.title}</h3>
        <span
          className="project-tile-subtitle"
          style={{ display: displaySubtitle }}
        >
          {props.subtitle}
        </span>
        <Button
          color="white"
          text={t("moreDetails")}
          onPress={() => {
            if (props.onClick) props.onClick(props.projectKey);
          }}
          visible={!!isButtonVisible}
        />
      </div>
      <img
        width={"100%"}
        height={"100%"}
        src={props.imageSrc}
        style={{ borderRadius: props.useRounded ? "8px" : "0px" }}
      />
    </div>
  );
}

import { Person } from "../person/Person";
import "./FounderInfo.css";

interface IFounderInfoProps {
  name: string;
  descriptionText: string;
  imageSrc: string;
  position: "left" | "right";
}

export function FounderInfo(props: IFounderInfoProps) {
  return (
    <div className={`white-box about-founder founder-${props.position}`}>
      <Person imageSrc={props.imageSrc} />
      <div className="founder-info">
        <h2>{props.name}</h2>
        <span>{props.descriptionText}</span>
      </div>
    </div>
  );
}

import "./Footer.css";
import Email from "../../assets/icons/email.png";
import Instagram from "../../assets/icons/instagram.png";
import { useTranslation } from "react-i18next";
import { EMAIL, INSTAGRAM_URL } from "../../Constants";

export function Footer() {
  const { t } = useTranslation();
  function triggerExternalLink(url: string) {
    window.open(url, "_blank")?.focus();
  }
  return (
    <div className="outer-footer">
      <div className="inner-footer">
        <h3>{t("getInTouch")}</h3>
        <div className="social">
          <img
            src={Email}
            onClick={() => triggerExternalLink(`mailto:${EMAIL}`)}
          />
          <img
            src={Instagram}
            onClick={() => triggerExternalLink(INSTAGRAM_URL)}
          />
        </div>
        <p>{t("developedBy")}</p>
      </div>
    </div>
  );
}

import { ProjectTile } from "../../components/projectTile/ProjectTile";
import "./Projects.css";
import { useEffect, useState } from "react";
import { StaticProjectLoader } from "../../projects/StaticProjectLoader";
import { IProject } from "../../projects/IProject";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Projects() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    new StaticProjectLoader().getAllProjects().then((results) => {
      setProjects(Object.values(results));
    });
  }, []);
  const [projects, setProjects] = useState<IProject[]>([]);

  function navToDetails(projectKey: string) {
    navigate(`/projects/${projectKey}`);
    // todo: projectKey is coming as undefined
    window.scrollTo({ top: 0 });
  }

  return (
    <div>
      <h1>{t("ourProjects")}</h1>
      <div className="projects-container">
        {projects.map((p) => (
          <ProjectTile
            imageSrc={p.imageSrc}
            key={p.key}
            clickable={true}
            projectKey={p.key}
            onClick={navToDetails}
            title={t(p.titleKey)}
          />
        ))}
      </div>
    </div>
  );
}

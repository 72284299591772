import "./Person.css";

interface IPersonProps {
  imageSrc: string;
  name?: string;
}

export function Person(props: IPersonProps) {
  return (
    <div className="person">
      <img src={props.imageSrc} />
      <p>{props.name}</p>
    </div>
  );
}

import { useTranslation } from "react-i18next";
import "./header.css";
import Icon from "../../assets/icon.png";
import IconBigger from "../../assets/logo.png";
import { LanguageSelector } from "../language/LanguageSelector";
import { Link } from "react-router-dom";

function scrollToBottom() {
  window.scrollTo({ behavior: "smooth", top: 999999 });
}
export function Header() {
  const { t } = useTranslation();
  return (
    <header className="header">
      <Link className="header-logo-without-text" to="/">
        <img src={Icon} width={"30px"} />
      </Link>
      <Link className="header-logo-with-text" to="/">
        <img src={IconBigger} width={"160px"} />
      </Link>
      <div className="header-contact-links">
        <Link to="/projects">{t("ourProjects")}</Link>
        <Link to="/about">{t("aboutUs")}</Link>
        <a onClick={scrollToBottom}>{t("contactUs")}</a>
      </div>
      <LanguageSelector />
    </header>
  );
}

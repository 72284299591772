import { useTranslation } from "react-i18next";
import "./Main.css";
import { MelanciaBanner } from "../../components/banner/MelanciaBanner";
import { ProjectTile } from "../../components/projectTile/ProjectTile";
import Sabrina from "../../assets/people/Sabrina.png";
import Lity from "../../assets/people/Lity.png";
import Vika from "../../assets/people/Vika.png";
import { Person } from "../../components/person/Person";
import { useEffect, useState } from "react";
import { IProject } from "../../projects/IProject";
import { StaticProjectLoader } from "../../projects/StaticProjectLoader";
import { Button } from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { NUMBER_OF_PROJECTS_IN_HOME } from "../../Constants";

function Main() {
  const [projects, setProjects] = useState<IProject[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function navToProjects() {
    navigate("/projects");
    window.scrollTo({ top: 0 });
  }

  function navToAbout() {
    navigate("/about");
    window.scrollTo({ top: 0 });
  }

  useEffect(() => {
    new StaticProjectLoader()
      .getAllProjects(NUMBER_OF_PROJECTS_IN_HOME)
      .then((results) => {
        setProjects(Object.values(results));
      });
  }, []);

  return (
    <div className="App">
      <MelanciaBanner />
      <h1>{t("ourProjects")}</h1>
      <div className="projects-container">
        {projects.map((p) => (
          <ProjectTile
            projectKey={p.key}
            key={p.key}
            imageSrc={p.imageSrc}
            title={t(p.titleKey)}
            useRounded={true}
            subtitle={t(p.subtitleKey)}
          />
        ))}
      </div>
      <div style={{ justifySelf: "center", marginBottom: "64px" }}>
        <Button
          onPress={navToProjects}
          dimBackground
          color="white"
          text={t("seeAllProjects")}
        />
      </div>
      <h1>{t("meetTheTeam")}</h1>
      <div className="people-container">
        <Person imageSrc={Lity} name={t("lity")} />
        <Person imageSrc={Sabrina} name={t("sabrina")} />
        <Person imageSrc={Vika} name={t("vika")} />
      </div>
      <h2
        onClick={navToAbout}
        style={{
          cursor: "pointer",
          display: "inline-block",
          justifySelf: "center",
        }}
      >
        {t("getToKnowOurWork")}
      </h2>
    </div>
  );
}

export default Main;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import pt from "./pt.json";

const resources = {
  pt: {
    translation: pt,
  },
  en: {
    translation: en,
  },
};

export enum AvailableLanguages {
  BR = "pt",
  US = "en",
}

i18n
  .use(initReactI18next)
  .init({ resources, lng: "pt", interpolation: { escapeValue: false } });

export default i18n;

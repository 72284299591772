import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BR, US } from "country-flag-icons/react/3x2";
import { AvailableLanguages } from "../../i18n/i18n";
import "./LanguageSelector.css";

const countryToImageMap = {
  [AvailableLanguages.BR]: BR,
  [AvailableLanguages.US]: US,
};

const countryArray = Object.keys(countryToImageMap) as AvailableLanguages[];

function getNextLanguage(language: AvailableLanguages): AvailableLanguages {
  const next = countryArray.findIndex((l) => l === language) + 1;
  if (next >= countryArray.length || next === -1) {
    return countryArray[0]!;
  }
  return countryArray[next]!;
}

export function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const [language, setLang] = useState(AvailableLanguages.BR);
  function cycleLanguage() {
    const targetLang = getNextLanguage(language);
    i18n.changeLanguage(targetLang);
    setLang(targetLang);
  }
  const component = countryToImageMap[language];
  return (
    <div className="language-icon" onClick={cycleLanguage}>
      {React.createElement(component, { className: "country-flag" })}
      <span>{language.toUpperCase()}</span>
    </div>
  );
}

import { useTranslation } from "react-i18next";
import "./AboutUs.css";
import Lity from "../../assets/people/Lity.png";
import Logo from "../../assets/logo.png";
import Sabrina from "../../assets/people/Sabrina.png";
import Vika from "../../assets/people/Vika.png";
import { FounderInfo } from "../../components/FounderInfo/FounderInfo";

export function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <img className="about-logo" src={Logo} />
      <div className="about-melancia white-box">
        <span>{t("aboutMelanciaFilmes")}</span>
      </div>
      <FounderInfo
        descriptionText={t("aboutLity")}
        imageSrc={Lity}
        name={t("lity")}
        position="left"
      />
      <FounderInfo
        descriptionText={t("aboutSabrina")}
        imageSrc={Sabrina}
        name={t("sabrina")}
        position="right"
      />
      <FounderInfo
        descriptionText={t("aboutVika")}
        imageSrc={Vika}
        name={t("vika")}
        position="left"
      />
    </div>
  );
}

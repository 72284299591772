import "./MelanciaBanner.css";
import Logo from "../../assets/logo.png";

export function MelanciaBanner() {
  return (
    <div className="melancia-banner">
      <video
        className="melancia-banner-video"
        autoPlay
        loop
        muted
        src="./Banner-resized.mp4"
        type="video/mp4"
      />
      <div className="melancia-banner-video-overlay" />
      <img className="melancia-banner-logo" src={Logo} />
    </div>
  );
}

import { useLoaderData, useNavigate } from "react-router-dom";
import { ProjectTile } from "../../components/projectTile/ProjectTile";
import { IProject } from "../../projects/IProject";
import "./ProjectDetails.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/Button";

export function ProjectDetails() {
  const data = useLoaderData() as IProject;
  const { t } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/projects");
    window.scrollTo({ top: 0 });
  }
  return (
    <div className="project-details-tile-container white-box">
      <div className="project-details-corpus">
        <ProjectTile
          useRounded
          projectKey={data.key}
          imageSrc={data.imageSrc}
          title={data.titleKey}
        />
        <div className="project-description">
          <h1>{t(data.titleKey)}</h1>
          <span>{t(data.descriptionKey)}</span>
        </div>
      </div>
      <Button color="black" text={t("goBack")} onPress={goBack} />
    </div>
  );
}

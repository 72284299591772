import { IProject } from "./IProject";
import Elas from "../assets/projects/Elas 2.png";
import Espiral from "../assets/projects/Espiral.png";
import Gazeta from "../assets/projects/Gazeta.png";
import Insípida from "../assets/projects/Insípida.png";
import Quaquarela from "../assets/projects/Quaquarela.png";
import Contos from "../assets/projects/contos.png";
import MarDeRosas from "../assets/projects/marDeRosas.jpg";
import PratoFrio from "../assets/projects/pratoFrio.jpg";
import SemSinal from "../assets/projects/SemSinal.png";

const PROJECTS: Record<string, IProject> = {
  // TODO: Use enums here
  elas: {
    descriptionKey: "elasDescription",
    imageSrc: Elas,
    key: "elas",
    titleKey: "elasTitle",
    subtitleKey: "elasSubtitle",
  },
  espiral: {
    descriptionKey: "espiralDescription",
    imageSrc: Espiral,
    key: "espiral",
    titleKey: "espiralTitle",
    subtitleKey: "espiralSubtitle",
  },
  insipida: {
    descriptionKey: "insipidaDescription",
    imageSrc: Insípida,
    key: "insipida",
    titleKey: "insipidaTitle",
    subtitleKey: "insipidaSubtitle",
  },
  gazeta: {
    descriptionKey: "gazetaDescription",
    imageSrc: Gazeta,
    key: "gazeta",
    titleKey: "gazetaTitle",
    subtitleKey: "gazetaSubtitle",
  },
  quaquarela: {
    descriptionKey: "quaquarelaDescription",
    imageSrc: Quaquarela,
    key: "quaquarela",
    titleKey: "quaquarelaTitle",
    subtitleKey: "quaquarelaSubtitle",
  },
  contos: {
    titleKey: "contosTitle",
    descriptionKey: "contosDescription",
    subtitleKey: "contosSubtitle",
    key: "contos",
    imageSrc: Contos,
  },
  marDeRosas: {
    titleKey: "marDeRosasTitle",
    descriptionKey: "marDeRosasDescription",
    subtitleKey: "marDeRosasSubtitle",
    key: "marDeRosas",
    imageSrc: MarDeRosas,
  },
  pratoFrio: {
    titleKey: "pratoFrioTitle",
    descriptionKey: "pratoFrioDescription",
    subtitleKey: "pratoFrioSubtitle",
    key: "pratoFrio",
    imageSrc: PratoFrio,
  },
  SemSinal: {
    titleKey: "semSinalTitle",
    descriptionKey: "semSinalDescription",
    subtitleKey: "semSinalSubtitle",
    key: "semSinal",
    imageSrc: SemSinal,
  },
  "": {
    descriptionKey: "",
    imageSrc: "",
    key: "",
    titleKey: "",
    subtitleKey: "",
  },
};

export class StaticProjectLoader {
  public async loadProject(projectId: string) {
    const project = PROJECTS[projectId];
    if (!project) {
      return PROJECTS[""];
    }
    return project;
  }
  public async getAllProjects(top?: number) {
    const result = { ...PROJECTS };
    delete result[""];
    if (top) {
      const keys = Object.keys(result).slice(0, top);
      return keys.reduce(
        (obj, currKey) => ({ ...obj, [currKey]: PROJECTS[currKey] }),
        {}
      );
    }
    return result;
  }
}

import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./pages/Main/Main";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./i18n/i18n";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { Cask } from "./pages/Cask/Cask";
import { Projects } from "./pages/Projects/Projects";
import { ProjectDetails } from "./pages/ProjectDetails/ProjectDetails";
import { StaticProjectLoader } from "./projects/StaticProjectLoader";
import { EmptyCask } from "./pages/EmptyCask/EmptyCask";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <Cask />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/projects",
        element: <EmptyCask />,
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: ":projectId",
            element: <ProjectDetails />,
            loader: async ({ params }) => {
              return new StaticProjectLoader().loadProject(
                params["projectId"] || ""
              );
            },
          },
        ],
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
